<template>
  <div style="height: 500px; width: 500px">
    <FormLatLngPicker v-model="chosenLatLng" />

    <div>
      Chosen Lat Lng:
      <pre>{{ chosenLatLng }}</pre>
    </div>
  </div>
</template>

<script>
import FormLatLngPicker from '@/components/forms/elements/FormLatLngPicker.vue'

export default {
  name: 'Dashboard',
  components: {
    FormLatLngPicker
  },
  data() {
    return {
      chosenLatLng: []
    }
  }
}
</script>
